@import '../../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 0;
  gap: 10px;
  height: 100px;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px 0 0px;
    gap: 10px;
    width: 100%;
    margin: auto;
    box-shadow: none;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) { 
  .root {
  padding: 0 24px 0 95px;
}
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
}
.logoLink:hover {
  border-bottom: 4px solid var(--marketplaceColor);
  border-radius: 0;
}

/* Search */
.searchLink {
  min-width: 320px;
  height: 100%;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100);
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.takeAvailableSpace {
  flex-grow: 1;

  /* At 1024px, the translations might need more space than what searchLink otherwise gets. */
  min-width: 220px;
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  padding-right: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* These is used with Inbox, Sign up, and Log in */
.topbarLink {
  flex-shrink: 0;

  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    background-color: #3a5795 !important;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 17px;
    margin: 0;
  }
}
.topbarLink{
  border-color: #ff5a5f;
  background-color: #ff5a5f !important;
  font-size: .875rem;
  color: #FFF;
  padding: .8rem;
  border-radius: 4px;
}
.topbarLink:last-child{
  background-color: #3a5795 !important;

  &:hover {
    background-color: #ff5a5f !important;
  }
}
.topbarLinkLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 0px 0;
  text-decoration: inherit;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:hover {
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.align {
  text-align: left;
  justify-self: start;

  @media (--viewportMedium) {
    text-align: center;
    justify-self: center;
  }
}
.ctaButton {
  composes: align;
  display: inline-block;
  padding: 8px 20px;
  font-size: 15px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;
  color: white;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 500;
  margin-top:0px;
  cursor: pointer;
  border: none;

  &:hover {
    text-decoration: none;
    background-color: var(--colorButtonHover);
  }
}
.heroSectionSearch{
  display: flex;
  background: #FFF;
  align-items: center;
  border-radius: 4px;
  padding: 0 10px;
  margin: 0px 0;

  & select{
    border: 0px;
    width: 250px;
    box-shadow: none;
  }

  & h1{
    font-size: 68px;
    font-weight: 600;
  }
}



.menuSection{
  display: flex;
  align-items: center;
  gap: 9px;
  min-width: 720px;
  justify-content: flex-end;
  padding-right: 5%;

}
@media screen and (min-width: 1024px) and (max-width: 1280px) { 
  .menuSection{

    padding-right: 0%;
  }
}
.topbarCustomNav{
  min-height: 42px;
  font-size: 14px;
  font-weight: 400;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
}

.dialogPopupOverlay {
  position: fixed;
  top: auto;
  left: 10px;
  bottom: 10px;
  width: 450px;
  /* height: 100%; */
  /* background: rgba(255, 255, 255, 0.8);  */
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialogPopup {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dialogPopupCloseButton{
  border: 0px;
  float: right;
}

.cookiesManager{
  border-radius: 10px;
  width: 100%;
  height: 40px;
  background:rgb(58, 87, 149);
  color: white;
}

.cookiesButton{
  text-align: center;
}
.topbarSearch{
  border: #3a5795 solid 1px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  padding: 5px 16px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: 30px;
  box-shadow: #0000003d 1px 0px 9px 0px;
  cursor: pointer;
  bottom: 30px;
  background-color: white;
  line-height: 16px;


  & span{
    width: 180px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.searchContainer{
  display: flex;
  justify-content: center;
}
.listingSearch{
  margin: 0;
  & form{
    padding: 0px;

    & div{
      padding: 0 8px 0 8px;

      & input{
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}